import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Slide from '@mui/material/Slide';
import Grid from '@mui/material/Grid';
import BusyLoaderSimple from '../../../components/loading-spinner/busy-loader-simple';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

// eslint-disable-next-line react/prop-types
function GetCourtPicture({ open, handleClose, requestCourtPicture, courtImageUrl, isLoading, message }) {
  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
       <AppBar sx={{ position: 'relative', width: '100%' }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon/>
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Request Court Picture
          </Typography>
          <Button autoFocus color="inherit" onClick={handleClose}>
            close
          </Button>
        </Toolbar>
      </AppBar>
      <Grid container spacing={2} sx={{ padding: '2rem' }}>
        <Grid item xs={10}>
          <Button
            variant="contained"
            color="primary"
            onClick={requestCourtPicture}
          >
            Request Court Picture
          </Button>
        </Grid>
        {
          isLoading && (
            <Grid item xs={10}>
              <BusyLoaderSimple/>
            </Grid>
          )
        }
        <Grid item xs={10}>
          {
            !courtImageUrl ? (
                <Typography variant="h6" component="div" color="grey">
                  {message}
                </Typography>)
              : (
                <img
                  src={`https://dev-ball-machine.waltair.io/static/court-images/${courtImageUrl}`}
                  width="80%"
                  alt="court"
                />
                )
          }
        </Grid>
      </Grid>
    </Dialog>
  );
}

export default React.memo(GetCourtPicture);
