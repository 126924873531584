import React, { useCallback, useEffect, useRef, useState } from 'react';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@mui/material/Grid';
import DeviceInfoCard from '../device-info-card/device-info-card';
import lodash from 'lodash';
import {
  useParams,
} from 'react-router-dom';
import {
  GET_DEVICE_DETAILS,
  getDeviceDetailsSuccess,
} from '../devices.reducer';
import BusyLoaderSimple from '../../../components/loading-spinner/busy-loader-simple';
import Link from '@mui/material/Link';
import { socketInstance } from '../../../socket';
import GetCourtPicture from './get-court-picture';

const DeviceDetails = () => {
  const params = useParams();
  const deviceId = params.id || null;
  const dispatch = useDispatch();
  const socketRef = useRef(null);
  const {
    deviceDetails, actionInProgress,
  } = useSelector((state) => state.deviceInfo);
  const [open, setOpen] = React.useState(false);
  const [courtImageUrl, setCourtImageUrl] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState('No court picture available');

  // console.log('deviceDetails:', deviceDetails);

  const handleClickOpen = useCallback(() => {
    setCourtImageUrl('');
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setIsLoading(false);
    setCourtImageUrl('');
    setOpen(false);
  }, []);

  const requestCourtPicture = useCallback(async () => {
    setCourtImageUrl('');
    setIsLoading(true);
    // eslint-disable-next-line react/prop-types
    if (socketRef.current && socketRef.current.connected) {
      // eslint-disable-next-line react/prop-types
      await socketRef.current.emit('request_court_image', { deviceId });
    } else {
      setIsLoading(false);
      setMessage('Socket not connected');
    }
  }, [deviceId]);

  const receivedCourtImage = (data) => {
    console.log('received_court_image data:', data);
    const { status = '', file_name: fileName = '' } = data;
    if (status === 'success' && fileName) {
      setCourtImageUrl(fileName);
    }
    setIsLoading(false);
  };

  /* const handleOperateMotors = useCallback((deviceId, command) => {
    dispatch({
      type: OPERATE_MOTORS,
      payload: {
        deviceId,
        command,
      },
    });
  }, [
    dispatch,
    deviceId
  ]); */

  useEffect(() => {
    const authToken = localStorage.getItem('auth-token');
    const socket = socketInstance({
      authToken,
      deviceId,
    });

    function onConnect() {
      console.log(`connected to socket ID:: ${socket.id}, timestamp: ${new Date()}`);
      socketRef.current = socket;
    }

    function onDisconnect(reason) {
      console.log(`Socket connection status: ${socket.connected}, timestamp: ${new Date()}`);
      console.log('Socket connection lost. reason:', reason);
    }

    function connectError(err) {
      console.log(`connect_error due to ${err.message}`);
      console.log('Socket is active:', socket.active);
    }

    socket.on('connect', onConnect);
    socket.on('disconnect', onDisconnect);
    socket.on('connect_error', connectError);
    socket.on('received_court_image', receivedCourtImage);

    const deviceDetailsHandler = async (data) => {
      if (data && Object.keys(data).length > 0 && data.device_id === deviceId) {
        dispatch(getDeviceDetailsSuccess({ data: { deviceDetails: { ...data } } }));
      }
    };

    socket.on('device-details', deviceDetailsHandler);

    socket.connect();

    return () => {
      socket.off('connect', onConnect);
      socket.off('disconnect', onDisconnect);
      socket.off('connect_error', connectError);
      socket.off('device-details', deviceDetailsHandler);
      socket.off('received_court_image', receivedCourtImage);
      socket.disconnect();
    };
  }, []);

  useEffect(() => {
    if (!deviceId) {
      return;
    }
    dispatch({
      type: GET_DEVICE_DETAILS,
      payload: {
        deviceId
      },
    });
  }, [deviceId, dispatch]);

  /* useEffect(() => {
    socket.on('device-details', handleDeviceDetails);

    return () => {
      socket.off('device-details', handleDeviceDetails);
    };
  }, [deviceId, socket, handleDeviceDetails]); */

  return (<Stack direction="column" spacing={2} mb="2rem">
    <Box
      sx={{
        textAlign: 'center', marginBottom: '2rem'
      }}
    >
      <h1>Device Details</h1>
    </Box>
    <Stack
      direction="row"
      justifyContent="flex-end"
      alignItems="flex-end"
      spacing={2}
    >
      <Link href="#" underline="hover" onClick={handleClickOpen}>
        {'Open Court Picture Window'}
      </Link>
      <Link href="/device-list" underline="hover">
        {'Back to Device List'}
      </Link>
    </Stack>
    {/* {
      deviceDetails && deviceDetails.device_id && (
        <Card variant="outlined" sx={{ marginBottom: '2rem', padding: '1rem' }}>
          <Typography variant="h5" component="div" mb="2rem" color="grey">
            {'Send Commands To Device'}
          </Typography>
          <Stack spacing={2} direction="row">
            <Button variant="contained"
                    disabled={deviceDetails.current_command === 'start_motors' ||
                      deviceDetails.current_command === 'move_motors_test'}
                    endIcon={<StartIcon/>}
                    onClick={() => handleOperateMotors(deviceId, 'start_motors')}
            >
              {'Start motors'}
            </Button>
            <Button variant="contained"
                    disabled={deviceDetails.current_command === 'stop_motors'}
                    endIcon={<StopCircleIcon/>}
                    onClick={() => handleOperateMotors(deviceId, 'stop_motors')}
            >
              {'Stop motors'}
            </Button>
            <Button variant="contained"
                    disabled={deviceDetails.current_command === 'stop_motors'}
                    endIcon={<MoveUpIcon/>}
                    onClick={() => handleOperateMotors(deviceId, 'move_motors_test')}
            >
              {'Test run motors'}
            </Button>
          </Stack>
        </Card>
      )
    } */}
    {
      actionInProgress ? <BusyLoaderSimple/> : (
        <Box sx={{ width: '100%' }}>
          <Box sx={{ width: '100%' }}>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
              <Grid item xs={12} md={12} lg={6}>
                <Grid
                  container
                  direction="column"
                  justifyContent="flex-start"
                  alignItems="stretch"
                >
                  <DeviceInfoCard title={'General Info'}
                                  data={lodash.pick(deviceDetails, ['device_id', 'host_name'])}/>
                  <DeviceInfoCard title={'User Session Info'}
                                  data={lodash.pick(deviceDetails, ['current_auth_code',
                                    'current_auth_code_created_at',
                                    'previous_auth_code',
                                    'active_user_session_id',
                                    'last_user_session_end_time'])}/>
                  <DeviceInfoCard title={'CPU Info'} data={deviceDetails.cpu_info}/>
                  <DeviceInfoCard title={'Network Devices'} data={deviceDetails.network_devices}/>
                </Grid>
              </Grid>
              <Grid item xs={12} md={12} lg={6}>
                <Grid
                  container
                  direction="column"
                  justifyContent="flex-start"
                  alignItems="stretch"
                >
                  <DeviceInfoCard title={'Timestamps'}
                                  data={lodash.pick(deviceDetails, ['last_report_timestamp', 'updated_timestamp'])}/>
                  <DeviceInfoCard title={'Facility Details'}
                                  data={lodash.pick(deviceDetails, ['facility_id', 'facility_name'])}/>
                  <DeviceInfoCard title={'Memory Info'} data={deviceDetails.memory_info}/>
                  <DeviceInfoCard title={'Temperature Info'} data={deviceDetails.temperature_info}/>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Box>
      )
    }
    <GetCourtPicture
      open={open}
      handleClose={handleClose}
      deviceId={deviceId}
      socket={socketRef.current}
      requestCourtPicture={requestCourtPicture}
      courtImageUrl={courtImageUrl}
      isLoading={isLoading}
      message={message}
    />
  </Stack>);
};

export default DeviceDetails;
